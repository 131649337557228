<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <v-col lg="6">
          <div class="mb-10">
            <h5 class="font-weight-bold text-h5 text-typo mb-0">
              ЭЕШ xичээл сонгосон сурагчдын жагсаалт
              <span v-if="students">({{ students.length }})</span>
            </h5>
            <p class="text-sm text-body mb-0">
              Энэxүү xуудас нь xичээл сонгосон xүүxдүүдийн мэдээллий xаруулна.
            </p>
            <v-progress-linear
              v-if="loading"
              color="red"
              height="6"
              indeterminate
            ></v-progress-linear>
          </div>
        </v-col>
      </v-row>
      <v-card class="pa-4" v-if="userData.school&&showDetails">
        <v-card-text class="px-0 py-0 scoreTables mt-4">
          <v-simple-table v-if="students && lessons">
            <template v-slot:default>
              <thead>
                <tr>
                  <th rowspan="2">No</th>
                  <th rowspan="2">Нэр, овог</th>
                  <th rowspan="2">Бүлэг</th>
                  <th
                    v-for="(lesson, lindex) in lessons"
                    :key="'lesson' + lindex"
                  >
                    {{ lesson.name }} 
                  </th>
                </tr>
                <tr>
                  <th
                    @click="_printStudents(lesson)"
                    v-for="(lesson, lindex) in lessons"
                    :key="'lesson' + lindex"
                    class="text-center"
                  >
                    <p
                      class="blue--text mb-0"
                      v-if="students && lesson.students"
                    >
                      {{ checkAll(lesson) }}
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(student, sindex) in students"
                  :key="'student' + sindex"
                >
                  <td class="text-center" style="width: 1%">
                    {{ sindex + 1 }}
                  </td>
                  <td>
                    {{ student.firstName }}
                    <span style="color: #aaa">{{ student.lastName }}</span>
                  </td>
                  <td class="blue--text">
                    {{ student.STUDENT_GROUP_NAME }}
                  </td>
                  <td
                    class="text-center"
                    v-for="(lesson, lindex) in lessons"
                    :key="'lessonBody' + lindex"
                    :style="
                      checkLesson(student, lesson) == '+'
                        ? 'background-color: #ffeb3b '
                        : ''
                    "
                  >
                    {{ checkLesson(student, lesson) }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
      <v-card>
        <v-simple-table style="font-size: 10pt">
          <thead style="background-color: #ececec">
            <th style="width: 1%">No.</th>
            <th>Сургуулийн нэр</th>
            <th v-for="(lesson, lindex) in lessons" :key="'lesson' + lindex">
              <small>{{ lesson.name.replace("ЭЕШ-", "") }}</small>
            </th>
          </thead>
          <tbody v-if="citySchools">
            <tr>
              <td></td>
              <td></td>
              <td
                style="background-color: yellowgreen"
                v-for="(lesson, lindex) in lessons"
                :key="'lesson' + lindex"
              >
                {{ _getSumme(lesson) }}
              </td>
            </tr>
            <tr
              v-for="(school, sIndex) in citySchools.filter(
                (ss) => ss.students && ss.students.length > 0
              )"
              :key="school.id + sIndex"
            >
              <td style="width: 1%">{{ sIndex + 1 }}</td>
              <td style="width: 20" class="blue--text">
                {{ school.name }}
              </td>
              <td
                v-for="(lesson, lindex) in school.lessons"
                :key="'lesson' + lindex"
              >
                <span v-if="lesson.students && lesson.students.length == 0">
                  <v-icon x-small color="red">mdi-alert</v-icon>
                </span>
                <span v-else-if="lesson.students">{{
                  lesson.students.length
                }}</span>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
      <!-- <v-card class="pa-4" v-if="forCityManager">
        <template v-if="citySchools">
          <div
            v-for="(school, sIndex) in citySchools.filter(
              (ss) => ss.students && ss.students.length > 0
            )"
            :key="school.id + sIndex"
          >
            <h4 class="blue--text mt-4">
              {{ school.name }} - {{ _getSchoolNumber(school) }} -
              <span v-if="school.lessons"> {{ school.lessons.length }}</span>
            </h4>
            <v-simple-table>
              <thead style="background-color: #ececec">
                <tr>
                  <th>No.</th>
                  <template v-if="showDetails">
                    <th rowspan="2">Нэр, овог</th>
                    <th rowspan="2">Бүлэг</th>
                    <th rowspan="2">Сонголтын тоо</th>
                  </template>
                  <th
                    v-for="(lesson, lindex) in school.lessons"
                    :key="'lesson' + lindex"
                  >
                    {{ lesson.name }} -
                    <span v-if="lesson.students">{{
                      lesson.students.length
                    }}</span>
                  </th>
                </tr>
              </thead>
              <tbody v-if="showDetails">
                <tr
                  v-for="(student, studIndex) in school.students"
                  :key="'student' + studIndex"
                >
                  <td class="text-center" style="width: 1%">
                    {{ studIndex + 1 }}
                  </td>
                  <td>
                    {{ student.firstName }}
                    <span style="color: #aaa">{{ student.lastName }}</span>
                  </td>
                  <td class="blue--text">
                    {{ student.STUDENT_GROUP_NAME }}
                  </td>
                  <td>
                    <span v-if="student.selections">{{
                      student.selections.length
                    }}</span>
                    <span v-else>-</span>
                  </td>

                  <td
                    class="text-center"
                    v-for="(lesson, lindex) in school.lessons"
                    :key="'lessonBody' + lindex"
                    :style="
                      checkLesson(student, lesson) == '+'
                        ? 'background-color: #ffeb3b '
                        : ''
                    "
                  >
                    {{ checkLesson(student, lesson) }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </template>
      </v-card> -->
    </v-container>
  </v-app>
</template>
<script>
const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";
export default {
  name: "light-table-transparent",
  data() {
    return {
      loading: false,
      showDetails: false,
      forCityManager: false,
      actionstatus: null,
      search: "",
      snackbar: false,
      students: null,
      lessons: null,
      citySchools: null,
    };
  },
  computed: {
    ...sync("*"),
    filteredSchools() {
      return this.citySchools.filter(
        (ss) => ss.students && ss.students.length > 0
      );
    },
    allStudents() {
      var list = [];
      for (const ss of this.filteredSchools) {
        for (const student of ss.students) {
          list.push(student);
        }
      }
      return list;
    },
  },
  methods: {
    _getSumme(lesson) {
      var summe = 0;
      for (const school of this.citySchools) {
        var found = school.lessons.find((ll) => ll.id == lesson.id);
        if (found) summe = summe + (found.students ? found.students.length : 0);
      }
      return summe;
    },
    _getSchoolNumber(school) {
      var counter = 0;
      if (school.students) {
        for (const stud of school.students) {
          if (stud.selections) counter = counter + stud.selections.length;
        }
      }
      return counter;
    },
    _printStudents(lesson) {
      var counter = 0;
      for (const student of this.students) {
        if (student.selections) {
          var found = student.selections.find(
            (ss) => ss.yeshLessonCode == lesson.yeshLessonCode
          );
          if (found) {
            counter++;
            console.log(
              counter,
              student.firstName,
              student.email +
                " " +
                student.DATE_OF_BIRTH.replace("T00:00:00.000Z", "")
            );
          }
        }
      }
    },
    checkAll(lesson) {
      var counter = 0;
      for (const student of this.students) {
        if (student.selections) {
          var found = student.selections.find(
            (ele) => ele.lessonCategoryRef == lesson.ref.path
          );
          if (found) counter++;
        }
      }
      return counter;
    },
    checkLesson(student, lesson) {
      if (lesson.students) {
        var found = lesson.students.find((ele) => ele.id == student.id);
        if (found) {
          return "+";
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    },
  },
  async created() {
    if (this.userData.cityCode) {
      this.forCityManager = true;
      var lessons = await fb.dbeyesh800
        .collection("categories")
        .orderBy("yeshLessonCode", "asc")
        .get();
      this.lessons = [];
      for (const lesson of lessons.docs) {
        let doc = lesson.data();
        doc.id = lesson.id;
        doc.ref = lesson.ref;
        this.lessons.push(doc);
      }
      fb.db
        .collection("schools")
        .where("deleted", "==", false)
        .where("cityCode", "==", this.userData.cityCode)
        .orderBy("name", "asc")
        .onSnapshot((docs) => {
          this.citySchools = [];
          docs.forEach((doc) => {
            let school = doc.data();
            school.id = doc.id;
            school.ref = doc.ref;
            this.loading = true;
            if (
              school.cityCode &&
              this.userData.cityCode &&
              school.cityCode == this.userData.cityCode
            ) {
              school.students = [];
              school.ref
                .collection("students-" + school.currentYear)
                .where("deleted", "==", false)
                .where("ACADEMIC_LEVEL", "==", "12")
                .orderBy("firstName", "asc")
                .get()
                .then((docs) => {
                  school.students = [];
                  docs.forEach((student) => {
                    let stud = student.data();
                    stud.ref = student.ref;
                    stud.id = student.id;
                    stud.selections = [];
                    this.loading = true;
                    if (!stud.moved) {
                      stud.ref
                        .collection("lessons-interested-"+this.userData.school.currentYear)
                        .onSnapshot((querySnapshot) => {
                          stud.selections = [];
                          this.loading = false;
                          querySnapshot.forEach((doc) => {
                            let lesson = doc.data();
                            lesson.id = doc.id;
                            lesson.ref = doc.ref;
                            stud.selections.push(lesson);
                          });
                        });
                    }
                    school.students.push(stud);
                  });
                  this.loading = false;
                });

              this.citySchools.push(school);
            }
            //lesson
            school.lessons = [];
            this.loading = true;
            for (const ll of this.lessons) {
              let les = Object.assign({}, ll);
              les.ref
                .collection("lesson-students")
                .where("ppschool", "==", school.ref.path)
                .get()
                .then((studs) => {
                  this.loading = false;
                  les.students = [];
                  studs.forEach((doc) => {
                    let student = doc.data();
                    student.ref = doc.ref;
                    student.id = doc.id;
                    if (!student.moved) les.students.push(student);
                  });
                  school.lessons.push(les);
                });
            }
          });
        });
    }
    if (this.userData.school) {
      fb.dbeyesh800
        .collection("categories")
        .orderBy("yeshLessonCode", "asc")
        .onSnapshot((docs) => {
          this.lessons = [];
          docs.forEach((lesson) => {
            let ll = lesson.data();
            ll.ref = lesson.ref;
            ll.id = lesson.id;
            ll.text = ll.name;
            ll.students = null;
            ll.ref
              .collection("lesson-students")
              .where("ppschool", "==", this.userData.school.ref.path)
              .get()
              .then((studs) => {
                ll.students = [];
                studs.forEach((doc) => {
                  let student = doc.data();
                  student.ref = doc.ref;
                  student.id = doc.id;
                  if (!student.moved) ll.students.push(student);
                });
              });
            this.lessons.push(ll);
          });
        });
      this.userData.school.ref
        .collection("students-" + this.userData.school.currentYear)
        .where("deleted", "==", false)
        .where("ACADEMIC_LEVEL", "==", "12")
        .orderBy("firstName", "asc")
        .orderBy("lastName", "asc")
        .get()
        .then((students) => {
          this.students = [];
          students.forEach((student) => {
            let stud = student.data();
            stud.ref = student.ref;
            stud.id = student.id;
            stud.selections = [];
            if (!stud.moved) {
              stud.ref
                .collection(
                  "lessons-interested-" + this.userData.school.currentYear
                )
                .onSnapshot((querySnapshot) => {
                  stud.selections = [];
                  querySnapshot.forEach((doc) => {
                    let lesson = doc.data();
                    lesson.id = doc.id;
                    lesson.ref = doc.ref;
                    stud.selections.push(lesson);
                  });
                });
              this.students.push(stud);
            }
          });
        });
    }
  },
};
</script>
<style>
.scoreTables td,
.scoreTables th {
  border: 1px solid #dddddd;
  text-align: left;
  height: 25px !important;
}
</style>
